export default function useSignatureHelpers() {
  const documentStore = useDocumentStore()
  const { activeSignatureRequest, activeDocument } = storeToRefs(documentStore)
  const userStore = useUserStore()
  const { email, signatureQualities, signerIdentityEmail, emailVerified, hasSigningCredit } = storeToRefs(userStore)
  const directSignStore = useDirectSignStore()
  const { isDirectSign } = storeToRefs(directSignStore)
  const { createSnackbar } = useWidgetStore()
  const businessStore = useBusinessStore()
  const { seals } = storeToRefs(businessStore)

  const { $i18n } = useNuxtApp()

  const openForInvitation = computed((): boolean => {
    return (
      activeSignatureRequest.value?.signatures.length === 0 && activeSignatureRequest.value.statusOverall === 'OPEN'
    )
  })

  const userIsSrOwner = computed((): boolean => {
    return Boolean(email.value && activeSignatureRequest.value?.writeAccess.includes(email.value.toLowerCase()))
  })

  const srIsTerminalState = computed((): boolean => {
    return Boolean(activeSignatureRequest.value?.statusOverall?.toLowerCase() !== 'open')
  })

  const setupDirectSign = (isFreeUser: boolean, requestedQuality: string): boolean => {
    // When a user sets the quality to any quality we will automatically try
    // to create a directSign unless they are a free user because free users
    // can not pay for the invitees which would be required for directSign.
    return !isFreeUser && ['ses', 'aes', 'qes'].includes(requestedQuality)
  }

  const standardizedUpdateURL = computed((): string => {
    // Construct URL for signature standards update based on the current SR
    let standardsUpdateURL = `/profile/signature-standards/update?sr=${activeSignatureRequest.value?.id}`
    standardsUpdateURL += `&q=${activeSignatureRequest.value?.quality.substring(0, 3).toLowerCase()}`
    if (activeSignatureRequest.value?.legislation)
      standardsUpdateURL += `&l=${activeSignatureRequest.value?.legislation.toLowerCase()}`
    return standardsUpdateURL
  })

  const userWillSealSRWith = computed(
    (): {
      accountName: string
      displayName: string
      quality: string
      legislation: string
      provider: string
    }[] => {
      const requestQuality = activeSignatureRequest.value?.quality?.toLowerCase()
      if (!requestQuality || !signatureQualities?.value?.seal) return []
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const sealList = signatureQualities.value.seal[requestQuality]
      if (!sealList) return []
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const sealNamesForSrQuality = Object.keys(sealList) || []
      return seals.value
        .filter(({ accountName }) => sealNamesForSrQuality.includes(accountName))
        .map(({ displayName, accountName }) => {
          return {
            accountName,
            displayName,
            quality: sealList[accountName].quality.toLocaleLowerCase() as string,
            legislation: sealList[accountName].legislation as string,
            provider: sealList[accountName].provider as string,
          }
        })
    }
  )

  // Simplified version with only quality and legislation was introduced for no
  // account batch signature requests which also rely on this function but don't
  // have a fully fledged signature request to work with.
  const userWillSignSRWith = computed((): SigningDetails => {
    if (activeSignatureRequest.value.id && signatureQualities?.value) {
      const srQuality = stringToCamelCase(activeSignatureRequest.value.quality.toLowerCase())

      const srLegislation =
        srQuality === 'demo' || srQuality === 'aes' || srQuality === 'ses' || srQuality === 'part11'
          ? 'any'
          : activeSignatureRequest.value.legislation?.toLowerCase() || 'any'

      // SES directSign users won't have their email verified on first visit
      // which will result in bbq sending null for all qualities. wave will fix
      // this by setting ses.any: 'ses' in this case.
      if (srQuality === 'ses' && Boolean(signerIdentityEmail.value)) {
        return { legislation: 'any', quality: 'ses', provider: 'AIS' }
      }

      // aes_minimal, qes and everything else
      if (signatureQualities.value[srQuality]?.[srLegislation]) {
        return {
          quality: (signatureQualities.value[srQuality][srLegislation]?.quality?.toLocaleLowerCase() as string) ?? null,
          provider: (signatureQualities.value[srQuality][srLegislation]?.provider as string) ?? null,
          legislation:
            (signatureQualities.value[srQuality][srLegislation]?.legislation?.toLocaleLowerCase() as string) ?? null,
        }
      }
    }
    return { quality: null, legislation: null, provider: null }
  })

  const displayStatusOverallFn = (signatureRequest?: SignatureRequestData): string => {
    const statesMapping = {
      OPEN: 'pending',
      DECLINED: 'declined',
      SIGNED: 'completed',
      WITHDRAWN: 'withdrawn',
    }
    const statusOverall = signatureRequest?.statusOverall
    if (!statusOverall) return ''
    // Uploaded documents are handled only in the frontend.
    // Definition: status_overall === 'OPEN' and no signatures set yet.
    if (statusOverall === 'OPEN' && signatureRequest?.signatures.length === 0) return 'uploaded'
    return statesMapping[statusOverall] || ''
  }

  const displayStatusOverall = computed((): string => {
    const statusOverall = activeSignatureRequest.value?.statusOverall
    if (!statusOverall) return ''
    // Uploaded documents are handled only in the frontend.
    // Definition: status_overall === 'OPEN' and no signatures set yet.
    if (statusOverall === 'OPEN' && activeSignatureRequest.value?.signatures.length === 0) return 'uploaded'
    else if (statusOverall === 'OPEN') return 'pending'
    else if (statusOverall === 'DECLINED') return 'declined'
    else if (statusOverall === 'SIGNED') return 'completed'
    else if (statusOverall === 'WITHDRAWN') return 'withdrawn'
    else return ''
  })

  const documentTitle = computed((): string => {
    return activeDocument.value?.title || ''
  })

  const hasBrandingLogoInSr = computed((): boolean => {
    return Boolean(activeSignatureRequest.value?.branding?.logo)
  })

  const userIsSrObserver = computed((): boolean => {
    return email.value && activeSignatureRequest.value
      ? activeSignatureRequest.value.ccEmailAddresses.includes(email.value)
      : false
  })

  const requestOwnerName = computed((): string => {
    const ownerName: string | undefined = formattedSigReqOwnerName.value
    return ownerName || ''
  })

  const srBrandingLogo = computed((): string | undefined => {
    const logo = activeSignatureRequest.value?.branding?.logo?.url
    return logo || undefined
  })

  const userCanAddSigners = computed(() => {
    return userIsSrOwner.value && !srIsTerminalState.value
  })

  const userCanChangeSigners = computed((): boolean => {
    return (
      !srIsTerminalState.value &&
      !isDirectSign.value &&
      displayStatusOverall.value !== 'uploaded' &&
      !userIsSrObserver.value &&
      userHasOpenSignature.value
    )
  })

  const userCanDeclineDocument = computed((): boolean => {
    if (!activeSignatureRequest.value || !email.value) return false
    return userHasOpenSignature.value && !userIsSrOwner.value
  })

  const userCanWithdrawDocument = computed((): boolean => {
    if (!activeSignatureRequest.value || !email.value) return false
    return (
      !srIsTerminalState.value &&
      userIsSrOwner.value &&
      displayStatusOverall.value !== 'uploaded' &&
      !userIsSrObserver.value
    )
  })

  const userCanDeleteDocument = computed((): boolean => {
    if (!activeSignatureRequest.value || !email.value) return false
    return Boolean(
      (!isDirectSign.value && srIsTerminalState.value) ||
        (displayStatusOverall.value === 'uploaded' && !userIsSrObserver.value)
    )
  })

  const formattedSigReqOwnerName = computed((): string => {
    // if activeSigReq is undefined, return blank
    if (!activeSignatureRequest.value.id) return ''

    // else, determine if owner is API user and, if so, return biz name instead of owner name
    const isAPISigReq = activeSignatureRequest.value.owner.substring(0, 4) === 'api_'

    return isAPISigReq ? (activeSignatureRequest.value.bizName ?? '') : activeSignatureRequest.value.owner
  })

  /**
   * This function will only be called when a snackbar has to be shown
   * for directSign cases where the required quality (QES) or legislation is not met.
   * This can only happen in QES cases since for AES we provide the mobile AES flow.
   */
  const showCannotSignSnackbarForDs = (requestedQuality: string, requestedLegislation: string | undefined): void => {
    const isQes = requestedQuality === 'qes'
    if (!isDirectSign.value || !isQes) return
    // Because the legislation is no longer required, it is not always part
    // of the error message and only added if specified and the SR quality
    // is QES.
    const computeTranslations = () => {
      if (requestedLegislation === 'zertes')
        return {
          messageText: $i18n.t('signature_request.errors.no_data_match.direct_sign.qes.zertes.message_text'),
          actionText: $i18n.t('signature_request.errors.no_data_match.direct_sign.qes.zertes.cta_text'),
          actionHref: $i18n.t('signature_request.errors.no_data_match.direct_sign.qes.zertes.cta_link'),
        }
      if (requestedLegislation === 'eidas')
        return {
          messageText: $i18n.t('signature_request.errors.no_data_match.direct_sign.qes.eidas.message_text'),
          actionText: $i18n.t('signature_request.errors.no_data_match.direct_sign.qes.eidas.cta_text'),
          actionHref: $i18n.t('signature_request.errors.no_data_match.direct_sign.qes.eidas.cta_link'),
        }
    }
    const translations = computeTranslations()
    if (translations)
      void createSnackbar({
        action: {
          text: translations.actionText,
          handler() {
            void navigateTo(translations.actionHref)
          },
        },
        message: translations.messageText,
        indefinite: true,
      })
  }

  const tanMailData = computed(
    (): {
      subject: string | undefined
      companyBranding: CompanyBranding | undefined
    } => {
      const data = {
        subject: activeSignatureRequest.value?.title,
        companyBranding: activeSignatureRequest.value?.branding,
      }

      return objectToSnakeCase(data)
    }
  )

  const userIsPartOfSR = computed((): boolean => {
    if (!activeSignatureRequest.value) return false
    return activeSignatureRequest.value?.signatures.some(signature => signature.accountEmail === email.value)
  })

  const userHasSigningCredit = computed((): boolean => {
    // If this user hasSigningCredit then they will never be unable to sign
    // this SR because of payment issues.
    // However, users without signing credits which are the owner of a SR
    // won't be able to sign in any case.
    return Boolean(Boolean(hasSigningCredit.value) || activeSignatureRequest.value?.owner !== email.value)
  })

  const userHasOpenSignature = computed((): boolean => {
    return Boolean(
      activeSignatureRequest.value?.signatures.some(
        signature => signature.accountEmail === email.value && signature.statusCode?.toLowerCase() === 'open'
      )
    )
  })

  const userCannotSign = computed((): boolean => {
    // directSign users will never be blocked from signing by not having
    // their email verified as all of them will not have it verified.
    const emailNotVerified = !isDirectSign.value && !emailVerified.value
    const userCannotSignQuality = userWillSignSRWith.value && !userWillSignSRWith.value.quality
    return (emailNotVerified || userCannotSignQuality || !userHasSigningCredit.value) ?? false
  })

  return {
    openForInvitation,
    userIsSrOwner,
    srIsTerminalState,
    setupDirectSign,
    standardizedUpdateURL,
    userWillSealSRWith,
    userWillSignSRWith,
    displayStatusOverall,
    displayStatusOverallFn,
    documentTitle,
    hasBrandingLogoInSr,
    userIsSrObserver,
    requestOwnerName,
    srBrandingLogo,
    userCanAddSigners,
    userCanChangeSigners,
    userCanDeclineDocument,
    userCanWithdrawDocument,
    userCanDeleteDocument,
    formattedSigReqOwnerName,
    showCannotSignSnackbarForDs,
    tanMailData,
    userIsPartOfSR,
    userHasSigningCredit,
    userHasOpenSignature,
    userCannotSign,
  }
}
